<template>
  <div :class="menuClasses">
    <nav class="md:absolute inset-y-0 md:my-auto grid h-max md:w-[238px] gap-5">
      <div
        :class="{
          'nestedNavigation flex flex-col gap-y-[5px] overflow-y-clip duration-300':
            item.type === 'nested'
        }"
        v-for="(item, index) in menuItems"
        :key="index"
      >
        <template v-if="item.type === 'normal' && checkPermission(item.permission)">
          <router-link
            class="hover:bg-surf-slate/10 dark:hover:bg-surf-slate flex items-center gap-x-4 px-6 py-2.5 duration-300"
            :to="{ name: item.route }"
          >
            <Component :is="menuIcons[item.route]" :active="route.name === item.route" />
            <span :class="{ 'duration-300': true, 'opacity-0': menuCollapsed }">{{
              item.title
            }}</span>
          </router-link>
        </template>
        <template v-if="item.type === 'nested'">
          <div
            @click="openNested(item)"
            class="relative flex cursor-pointer items-center gap-x-4 px-6 py-2.5 duration-300 hover:bg-surf-slate/10 dark:hover:bg-surf-slate"
          >
            <Component :is="menuIcons[item.route]" :active="checkNestedRoutes(item.nestedItems)" />
            <span :class="{ 'duration-300': true, 'opacity-0': menuCollapsed }">{{
              item.title
            }}</span>
            <ArrowDownIcon />
          </div>
          <nav
            :ref="
              (element) => {
                element.style.maxHeight = item.opened ? `${element.scrollHeight}px` : '0px'
              }
            "
            class="flex flex-col gap-y-[13px] text-[17px]/[1.1] duration-300"
          >
            <span :key="index" v-for="(nestedItem, index) in item.nestedItems">
              <router-link
                v-if="checkPermission(nestedItem.permission)"
                :to="{ name: nestedItem.route }"
                class="flex items-center gap-x-5 px-7 hover:opacity-75 duration-300"
              >
                <Component
                  :is="menuIcons[nestedItem.route]"
                  :active="route.name === nestedItem.route"
                />
                <span
                  :class="{
                    'text-lush-green': route.name === nestedItem.route,
                    'opacity-0': menuCollapsed,
                    'duration-300': true
                  }"
                  >{{ nestedItem.title }}</span
                >
              </router-link>
            </span>
          </nav>
        </template>
      </div>
      <div
        @click="collapseMenu"
        class="collapseMenu flex cursor-pointer items-center gap-x-4 px-6 py-2.5 duration-300 hover:bg-surf-slate/10 dark:hover:bg-surf-slate"
      >
        <CollapseMenuIcon />
        <span :class="{ 'duration-300': true, 'opacity-0': menuCollapsed }">Collapse menu</span>
      </div>
    </nav>
    <div class="flex justify-between md:absolute bottom-8 md:w-[238px] px-6">
      <ModeToggle class="hover:bg-surf-slate/10 dark:hover:bg-surf-slate duration-300" />
      <ProfileIcon />
      <button
        @click="userStore.preventLogout()"
        class="flex justify-center items-center w-[46px] h-[46px] rounded-lg hover:bg-surf-slate/10 dark:hover:bg-surf-slate duration-300"
      >
        <SignOutIcon />
      </button>
    </div>
  </div>
</template>

<script setup>
import DashboardIcon from '@/components/icons/DashboardIcon.vue'
import CalculatorIcon from '@/components/icons/CalculatorIcon.vue'
import CollapseMenuIcon from '@/components/icons/CollapseMenuIcon.vue'
import { computed, onMounted, ref, inject, watch } from 'vue'
import ArrowDownIcon from '@/components/icons/ArrowDownIcon.vue'
import ProfileIcon from '@/components/icons/ProfileIcon.vue'
import AvatarIcon from '@/components/icons/AvatarIcon.vue'
import { useRoute } from 'vue-router'
import { collect } from 'collect.js'
import RatesIcon from '@/components/icons/RatesIcon.vue'
import AreasIcon from '@/components/icons/AreasIcon.vue'
import DiscountsIcon from '@/components/icons/DiscountsIcon.vue'
import CalendarIcon from '@/components/icons/CalendarIcon.vue'
import SignOutIcon from '@/components/icons/SignOutIcon.vue'
import PermissionsIcon from '@/components/icons/PermissionsIcon.vue'
import AuditLogIcon from '@/components/icons/AuditLogIcon.vue'
import ModeToggle from '@/components/ModeToggle.vue'
import { useUserStore } from '@/stores/user'
import CarIcon from '@/components/icons/CarIcon.vue'

const route = useRoute()
const menuIcons = {
  home: DashboardIcon,
  supercharger: CalculatorIcon,
  areas: AreasIcon,
  rates: RatesIcon,
  discounts: DiscountsIcon,
  calendar: CalendarIcon,
  profile: AvatarIcon,
  permissions: PermissionsIcon,
  users: AvatarIcon,
  audit: AuditLogIcon,
  'ride-histories-requests': CarIcon
}
const menuItems = ref()
// const nestedItemsNavigation = ref()
const userStore = useUserStore()
const checkPermission = (item) => {
  return userStore.checkPermission(item)
}
//Checking nested items on menu to active route for
//class active on main icon of nesteds
const checkNestedRoutes = (items) => {
  const flatMap = collect(items).flatMap((item) => item.route)
  return flatMap.contains(route.name)
}
const collapseMenu = () => {
  menuCollapsed.value = !menuCollapsed.value
}

const menuClasses = computed(() => {
  return {
    'menu absolute md:fixed left-0 md:top-0 z-50 w-full h-screen overflow-x-clip md:rounded-r-[40px] bg-white dark:bg-steel-gray duration-300 max-md:flex max-md:flex-col-reverse max-md:justify-between max-md:pt-6 max-md:pb-3.5 text-xl md:shadow-xl dark:shadow-none': true,
    'max-md:top-0 md:w-[238px]': !menuCollapsed.value,
    'max-md:top-[calc(-100%_+_76px)] md:w-[94px] max-md:!bg-opacity-0': menuCollapsed.value
  }
})

const openNested = (item) => {
  if (item.type === 'nested') {
    item.opened = !item.opened
  }
}

const menuCollapsed = inject('menuCollapsed')

onMounted(() => {
  menuItems.value = [
    {
      type: 'normal',
      route: 'home',
      title: 'Calculator',
      permission: 'home.view'
    },
    {
      type: 'normal',
      route: 'ride-histories-requests',
      title: 'Ride Request',
      permission: 'ride-history-requests.view'
    },
    {
      type: 'nested',
      route: 'supercharger',
      title: 'Settings',
      opened: false,
      nestedItems: [
        {
          type: 'normal',
          route: 'areas',
          title: 'Areas',
          permission: 'areas.view'
        },
        {
          type: 'normal',
          route: 'rates',
          title: 'Rates',
          permission: 'rates.view'
        },
        {
          type: 'normal',
          route: 'discounts',
          title: 'Coefficients',
          permission: 'discounts.view'
        },
        {
          type: 'normal',
          route: 'calendar',
          title: 'Calendar',
          permission: 'callendar.view'
        }
      ]
    },
    // {
    //   type: 'normal',
    //   route: 'profile',
    //   title: 'Profile',
    //   permission: 'users.view'
    // },
    // {
    //   type: 'normal',
    //   route: 'users',
    //   title: 'Users',
    //   permission: 'users.view'
    // }
    {
      type: 'nested',
      route: 'permissions',
      title: 'Permissions',
      opened: false,
      nestedItems: [
        {
          type: 'normal',
          route: 'users',
          title: 'Users',
          permission: 'users.view'
        },
        {
          type: 'normal',
          route: 'audit',
          title: 'Audit Log',
          permission: 'audit.view'
        }
      ]
    }
  ]
})

watch(menuCollapsed, () => {
  if (!menuCollapsed.value) {
    document.querySelector('body').classList.add('max-md:overflow-hidden')
  } else {
    document.querySelector('body').classList.remove('max-md:overflow-hidden')
  }
})
</script>

<style scoped></style>
