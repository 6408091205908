<template>
  <main
    :class="menuCollapsed ? 'md:pl-[140px]' : 'md:pl-[284px]'"
    class="pl-6 pr-6 pt-[70px] pb-[140px] text-sm/[1.42] lg:text-lg/[1.38] duration-300"
  >
    <h1 class="mb-9 text-[40px] font-bold leading-[1.1] lg:text-[80px]">Users</h1>

    <form v-if="userData" action="" class="w-full md:w-2/3 lg:w-1/2 space-y-16">
      <div class="grid gap-4">
        <label class="space-y-2">
          <span>First name</span>
          <input
            name="first_name"
            class="w-full rounded-full px-[22px] py-5 text-silver-fog placeholder-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green border focus:border-transparent border-[#6B7280]"
            placeholder="…"
            v-model="userData.first_name"
          />
        </label>
        <label class="space-y-2">
          <span>Last name</span>
          <input
            name="last_name"
            class="w-full rounded-full px-[22px] py-5 text-silver-fog placeholder-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green border focus:border-transparent border-[#6B7280]"
            placeholder="…"
            v-model="userData.last_name"
          />
        </label>
        <label class="space-y-2">
          <span>Email</span>
          <input
            name="email"
            class="w-full rounded-full px-[22px] py-5 text-silver-fog placeholder-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green border focus:border-transparent border-[#6B7280]"
            placeholder="…"
            v-model="userData.email"
          />
        </label>
        <label class="space-y-2">
          <span>Password (keep empty if don't wanna change it)</span>
          <input
            name="password"
            class="w-full rounded-full px-[22px] py-5 text-silver-fog placeholder-silver-fog focus-visible:outline focus-visible:outline-2 focus-visible:outline-lush-green border focus:border-transparent border-[#6B7280]"
            placeholder="…"
            v-model="userData.password"
          />
        </label>
        <label class="flex items-center gap-2 w-max">
          <span>is Admin</span>

          <input
            @change="changeIsAdmin"
            v-model="userData.isAdmin"
            class="h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[3px] after:h-5 after:w-5 after:rounded-full !bg-none border-none after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:!bg-lush-green checked:after:absolute checked:after:z-[2] checked:after:ml-[1.0625rem] after:ml-[-0.25rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
            type="checkbox"
          />
        </label>
      </div>
      <div class="" v-if="userData.isAdmin">
        <div
          v-for="testItem in permissionData"
          :key="testItem.title"
          class="grid grid-cols-2 gap-8 h-14 border-b border-shadowy-steel"
        >
          <label class="group flex justify-between items-center w-full cursor-pointer select-none">
            <span class="group-hover:opacity-75 duration-300">{{ testItem.title }}</span>
            <input
              @change="changePermission(testItem)"
              v-model="testItem.isAllowed"
              class="h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[3px] after:h-5 after:w-5 after:rounded-full !bg-none border-none after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:!bg-lush-green checked:after:absolute checked:after:z-[2] checked:after:ml-[1.0625rem] after:ml-[-0.25rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
              type="checkbox"
            />
          </label>
          <div
            v-if="testItem.isAllowed && testItem.options.list.length"
            class="multiselect relative uppercase"
          >
            <div
              @click="toggleOptions(testItem)"
              class="multiselectHeading flex items-center h-full cursor-pointer overflow-x-clip whitespace-nowrap rounded-md pl-3 pr-9"
            >
              <span>{{ selectedOptions(testItem.options.list) }}</span>
              <div
                class="absolute right-0 top-[calc(50%-12px)] my-auto px-3 bg-white dark:bg-[#2B2D32]"
              >
                <img src="@/assets/images/arrow.svg" alt="arrowIcon" />
              </div>
            </div>
            <div
              :class="{
                'options absolute left-0 top-full z-10 w-max min-w-full divide-y divide-midnight-ink rounded-md bg-[#eaeaeb] dark:bg-shadowy-steel': true,
                hidden: testItem.options.isHidden
              }"
            >
              <label
                v-for="option in testItem.options.list"
                :key="option.value"
                class="flex cursor-pointer items-center gap-x-3 p-3"
              >
                <input
                  v-model="option.isSelected"
                  class="h-6 w-6 cursor-pointer appearance-none rounded-[5px] border border-lush-green bg-center bg-no-repeat checked:bg-[url(@/assets/images/tick.svg)] checked:!bg-lush-green checked:bg-blend-difference focus:ring-lush-green focus:bg-blend-difference"
                  type="checkbox"
                />
                <span>{{ option.value }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <button
        @click.prevent="userSubmit"
        class="w-full rounded-full border border-lush-green bg-lush-green py-[22px] font-semibold leading-[1.1] text-midnight-ink duration-300 disabled:bg-transparent dark:disabled:text-white hover:bg-transparent dark:hover:text-white"
      >
        Save
      </button>
    </form>
  </main>
</template>

<script setup>
import { inject, onBeforeMount, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useFetcher } from '@/compose/axios'
import { collect } from 'collect.js'
import router from '@/router'

const { axiosInstance } = useFetcher({ baseUrl: import.meta.env.VITE_APP_API_URL })
const menuCollapsed = inject('menuCollapsed')

const route = useRoute()

const userData = ref(null)

const changeIsAdmin = () => {
  if (!userData.value.isAdmin) {
    permissionData.value.forEach((permission) => {
      permission.isAllowed = false
      permission.options.isHidden = false
      permission.options.list.forEach((option) => {
        option.isSelected = false
      })
    })
    userData.value.permissions = []
  }
}

const changePermission = (testItem) => {
  if (!testItem.isAllowed) {
    testItem.options.isHidden = false
    testItem.options.list.forEach((option) => {
      option.isSelected = false
    })
  }
}

const permissionData = ref([
  {
    title: 'Home',
    id: 'home',
    isAllowed: false,
    options: {
      isHidden: true,
      list: []
    }
  },
  {
    title: 'Audit',
    id: 'audit',
    isAllowed: false,
    options: {
      isHidden: true,
      list: []
    }
  },
  {
    title: 'Areas',
    id: 'areas',
    isAllowed: false,
    options: {
      isHidden: true,
      list: [
        { value: 'create', isSelected: false },
        { value: 'edit', isSelected: false },
        { value: 'delete', isSelected: false }
      ]
    }
  },
  {
    title: 'Rates',
    id: 'rates',
    isAllowed: false,
    options: {
      isHidden: true,
      list: [{ value: 'edit', isSelected: false }]
    }
  },
  {
    title: 'Coefficients',
    id: 'discounts',
    isAllowed: false,
    options: {
      isHidden: true,
      list: [
        { value: 'create', isSelected: false },
        { value: 'edit', isSelected: false },
        { value: 'delete', isSelected: false },
        { value: 'archive', isSelected: false },
        { value: 'duplicate', isSelected: false }
      ]
    }
  },
  {
    title: 'Calendar',
    id: 'callendar',
    isAllowed: false,
    options: {
      isHidden: true,
      list: [
        { value: 'create', isSelected: false },
        { value: 'edit', isSelected: false },
        { value: 'delete', isSelected: false }
      ]
    }
  },
  {
    title: 'Users',
    id: 'users',
    isAllowed: false,
    options: {
      isHidden: true,
      list: [
        { value: 'create', isSelected: false },
        { value: 'edit', isSelected: false },
        { value: 'delete', isSelected: false }
      ]
    }
  },
  {
    title: 'Ride History Requests',
    id: 'ride-history-requests',
    isAllowed: false,
    options: {
      isHidden: true,
      list: []
    }
  }
])

watch(
  permissionData,
  (newVal) => {
    let permissions = []
    newVal.forEach((e) => {
      if (e.isAllowed === true) {
        permissions.push([e.id, 'view'].join('.'))
      }

      if (e.isAllowed === true) {
        e.options.list.forEach((option) => {
          if (option.isSelected === true) {
            permissions.push([e.id, option.value].join('.'))
          }
        })
      }
    })

    userData.value.permissions = permissions
  },
  { deep: true }
)

const toggleOptions = (testItem) => {
  if (!testItem.options.isHidden) {
    testItem.options.isHidden = true
  } else {
    permissionData.value.forEach((e) => {
      e.options.isHidden = true
      testItem.options.isHidden = false
    })
  }
}

const selectedOptions = (list) => {
  const result = list
    .filter((option) => option.isSelected)
    .map((option) => option.value)
    .join(', ')
  return result ? result : '…'
}

const mountPermissions = (permissions) => {
  let splitString
  permissions.forEach((e) => {
    splitString = e.split('.')
    if (splitString[1] === 'view') {
      for (const [key, value] of Object.entries(permissionData.value)) {
        if (value.id === splitString[0]) {
          permissionData.value[key].isAllowed = true
        }
      }
    }

    if (splitString[1] !== 'view') {
      for (const [key, value] of Object.entries(permissionData.value)) {
        permissionData.value[key].options.list.forEach((option) => {
          if (option.value === splitString[1] && value.id === splitString[0]) {
            option.isSelected = true
          }
        })
      }
    }
  })
}

const userSubmit = () => {
  const id = route.params.id

  axiosInstance.post('/admin/users/' + id, userData.value).then(() => {
    router.push('/users/')
  })
}

onBeforeMount(() => {
  const id = route.params.id

  axiosInstance.get('/admin/users/' + id).then((response) => {
    userData.value = response.data.data
    if (userData.value?.permissions) {
      userData.value.permissions = collect(userData.value.permissions).pluck('name').toArray()
      mountPermissions(userData.value.permissions)
    }
  })
})
</script>
